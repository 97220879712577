.react-calendar {
  font-family: "Inter", sans-serif;
  border: none;
}

.react-calendar__tile--active {
  border-radius: 8px;
}

.react-calendar__tile--now {
  color: #000000;
  background: none;
  /* background-color: #2C6EF2; */
  border-radius: 8px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: #ffff;
  background-color: #2C6EF2;
  border-radius: 8px;
}
